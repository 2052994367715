import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { TabPanel, Tabs } from 'react-tabs';
import CardModal from '../CardModal';
import { applyFiltersFor, getUniqueItems } from '../all_items/ItemContent';
import { useUnisat } from '../../../pages/WalletConnect/hooks/useUnisat';

export const applySearching = (items, searchingPhrase) => {
  if (!searchingPhrase || searchingPhrase?.length === 0) return items;
  if (!items) return;

  const dataAfterSearching = items.filter((item) => {
    const metaKeys = Object.keys(item.meta);

    return metaKeys.some((key) => {
      const value = item.meta[key];
      if (typeof value === 'string') {
        return value.toLowerCase().includes(searchingPhrase.toLowerCase());
      }
      return false;
    });
  });

  return dataAfterSearching;
};

const ItemContent = ({ filters, results, searchingPhrase }) => {
  const [visible, setVisible] = useState(15);
  const [modalShow, setModalShow] = useState(false);
  const { inscriptions } = useUnisat();

  const data = results.map((result) => result.data);
  const resultsNotReady = results.some((result) => result.data === undefined) || results.length === 0;
  if (resultsNotReady) return;

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 5);
  };

  const uniqueFilteredItems = applyFiltersFor(getUniqueItems(data), filters);
  const searchedItems = applySearching(uniqueFilteredItems, searchingPhrase);

  return (
    <Fragment>
      <div className="tf-section sc-explore-2">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="flat-tabs explore-tab">
                <Tabs>
                  <TabPanel>
                    {searchedItems.slice(0, visible).map((item, index) => (
                      <div
                        key={item.id}
                        className={`sc-card-product explode style2 mg-bt ${item.feature ? 'comingsoon' : ''} `}
                      >
                        <div className="card-media" style={{ height: '360px' }}>
                          <Link
                            to="/item-details-01"
                            state={{ ...item, inscription: inscriptions.list[index].inscriptionId }}
                          >
                            <img
                              src={`https://darkcity.infura-ipfs.io/ipfs/${item.meta.image}`}
                              alt={item.meta.name}
                              style={{ height: '100%', objectFit: 'cover' }}
                            />
                          </Link>
                        </div>
                        <div className="card-title">
                          <h5>
                            <Link
                              to="/item-details-01"
                              state={{ ...item, inscription: inscriptions.list[index].inscriptionId }}
                            >
                              {item.meta.name}
                            </Link>
                          </h5>
                        </div>
                        <div className="meta-info">
                          <div className="author">
                            <div className="info">
                              <span>{item.meta.type}</span>
                            </div>
                          </div>
                          <div
                            className="tags"
                            style={{
                              width: 'auto',
                              height: '30px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '0px 12px',
                            }}
                          >
                            {item.meta.rarity}
                          </div>
                        </div>
                      </div>
                    ))}
                    {visible < searchedItems.length && (
                      <div className="col-md-12 wrap-inner load-more text-center">
                        <Link
                          to="#"
                          id="load-more"
                          className="sc-button loadmore fl-button pri-3"
                          onClick={showMoreItems}
                        >
                          <span>Load More</span>
                        </Link>
                      </div>
                    )}
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </Fragment>
  );
};

export default ItemContent;
