import React from 'react';
import { useLocation } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';

const ItemDetails01 = () => {
  const location = useLocation();
  const item = location.state;
  const itemDetails = item.meta;

  return (
    <div className="item-details">
      <Header />

      <div
        className="tf-section tf-item-details"
        style={{
          paddingTop: '115px',
          paddingBottom: '20px',
          minHeight: '830px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="themesflat-container">
          <div className="row" style={{ paddingBottom: '20px' }}>
            <div className="col-xl-6 col-md-12" style={{ justifyContent: 'center', display: 'flex' }}>
              <div className="content-left">
                <div className="media">
                  <img src={`https://darkcity.infura-ipfs.io/ipfs/${itemDetails.image}`} alt={itemDetails.name} />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="content-right">
                <div className="sc-item-details">
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <h2 className="style2">{itemDetails.name}</h2>
                    <p>{itemDetails.type}</p>
                    <p>{itemDetails.rarity}</p>
                    <a
                      href={`https://ordinals.com/inscription/${item.inscription_id || item.inscription}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ fontSize: '14px', textDecoration: 'underline' }}
                    >
                      Link to ordinals inscription
                    </a>
                    {itemDetails?.trait && (
                      <ul style={{ marginTop: '18px' }}>
                        <p style={{ fontSize: '14px' }}>Traits</p>
                        {Object.entries(itemDetails.trait).map(([trait, traitValue]) => (
                          <li key={trait} className="py-2 style1" style={{ fontSize: '14px' }}>
                            {trait} {traitValue}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  {/* <div className="meta-item">
                    <div className="left">
                      <span className="viewed eye">225</span>
                      <span to="/login" className="liked heart wishlist-button mg-l-8">
                        <span className="number-like">100</span>
                      </span>
                    </div>
                    <div className="right">
                      <Link to="#" className="share"></Link>
                      <Link to="#" className="option"></Link>
                    </div>
                  </div> */}
                  <div className="client-infor sc-card-product" style={{ marginTop: '16px' }}>
                    {/* <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img src={img6} alt="Axies" />
                        </div>
                        <div className="info">
                          <span>Owned By</span>
                          <h6>
                            {' '}
                            <Link to="/author-02">Ralph Garraway</Link>{' '}
                          </h6>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img src={img7} alt="Axies" />
                        </div>
                        <div className="info">
                          <span>Create By</span>
                          <h6>
                            {' '}
                            <Link to="/author-02">Freddie Carpenter</Link>{' '}
                          </h6>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <p>{itemDetails.description}</p>
                  {/* <div className="meta-item-details style2">
                    <div className="item meta-price">
                      <span className="heading">Current Bid</span>
                      <div className="price">
                        <div className="price-box">
                          <h5> 4.89 ETH</h5>
                          <span>= $12.246</span>
                        </div>
                      </div>
                    </div>
                    <div className="item count-down">
                      <span className="heading style-2">Countdown</span>
                      <Countdown date={Date.now() + 500000000}>
                        <span>You are good to go!</span>
                      </Countdown>
                    </div>
                  </div> */}
                  {/* <Link to="/wallet-connect" className="sc-button loadmore style bag fl-button pri-3">
                    <span>Place a bid</span>
                  </Link> */}
                  {/* <div className="flat-tabs themesflat-tabs">
                    <Tabs>
                      <TabList>
                        <Tab>Bid History</Tab>
                        <Tab>Info</Tab>
                        <Tab>Provenance</Tab>
                      </TabList>

                      <TabPanel>
                        <ul className="bid-history-list">
                          {dataHistory.map((item, index) => (
                            <li key={index} item={item}>
                              <div className="content">
                                <div className="client">
                                  <div className="sc-author-box style-2">
                                    <div className="author-avatar">
                                      <Link to="#">
                                        <img src={item.img} alt="Axies" className="avatar" />
                                      </Link>
                                      <div className="badge"></div>
                                    </div>
                                    <div className="author-infor">
                                      <div className="name">
                                        <h6>
                                          <Link to="/author-02">{item.name} </Link>
                                        </h6>{' '}
                                        <span> place a bid</span>
                                      </div>
                                      <span className="time">{item.time}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="price">
                                  <h5>{item.price}</h5>
                                  <span>= {item.priceChange}</span>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </TabPanel>
                      <TabPanel>
                        <ul className="bid-history-list">
                          <li>
                            <div className="content">
                              <div className="client">
                                <div className="sc-author-box style-2">
                                  <div className="author-avatar">
                                    <Link to="#">
                                      <img src={img1} alt="Axies" className="avatar" />
                                    </Link>
                                    <div className="badge"></div>
                                  </div>
                                  <div className="author-infor">
                                    <div className="name">
                                      <h6>
                                        {' '}
                                        <Link to="/author-02">Mason Woodward </Link>
                                      </h6>{' '}
                                      <span> place a bid</span>
                                    </div>
                                    <span className="time">8 hours ago</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </TabPanel>
                      <TabPanel>
                        <div className="provenance">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book. It has survived not only five
                            centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop publishing software like Aldus PageMaker including
                            versions of Lorem Ipsum.
                          </p>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <LiveAuction data={liveAuctionData} /> */}
      <Footer />
    </div>
  );
};

export default ItemDetails01;
