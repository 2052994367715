import img1 from '../images/slider/slide_1.png';
import img2 from '../images/slider/slide_2.png';
import imgbg1 from '../images/slider/bg_slide_1.png';
import imgbg2 from '../images/slider/bg_slide_1.png';

const heroSliderData = [
  {
    title_1: 'Discover, and collect',
    title_2: 'extraordinary',
    title_3: 'Monster NFTs',
    description: 'Marketplace for monster character cllections non fungible token NFTs',
    class: 'center',
  },
  {
    title_1: 'Discover, and collect',
    title_2: 'extraordinary',
    title_3: 'Monster NFTs',
    description: 'Marketplace for monster character cllections non fungible token NFTs',
    img: img1,
    imgbg: imgbg1,
    class: 'left',
  },
  {
    title_1: 'Discover, and collect',
    title_2: 'extraordinary',
    title_3: 'Monster NFTs',
    description: 'Marketplace for monster character cllections non fungible token NFTs',
    img: img2,
    imgbg: imgbg2,
    class: 'right',
  },
];

export default heroSliderData;
