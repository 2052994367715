import React from 'react';
import Header from '../components/header/HeaderStyle2';
import ItemContent from '../components/layouts/your_inventory/ItemContent';
import SideBar from '../components/layouts/your_inventory/SideBar';
import { useState } from 'react';

import { useQueries } from '@tanstack/react-query';
import { useUnisat } from './WalletConnect/hooks/useUnisat';

const fetchData = async (url) => {
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

const YourInventory = () => {
  const [filters, setFilters] = useState({});
  const { inscriptions } = useUnisat();
  const [searchingPhrase, setSearchingPhrase] = useState('');

  const results = useQueries({
    queries: (inscriptions?.list ?? []).map((inscription) => ({
      queryKey: ['inscription', inscription.content],
      queryFn: () => fetchData(inscription.content),
      enabled: !!inscription?.content,
    })),
  });

  return (
    <div className="home-8">
      <Header searchingPhrase={searchingPhrase} setSearchingPhrase={setSearchingPhrase} />
      <section className="tf-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-box-17">
              <SideBar setFilters={setFilters} results={results} />
            </div>
            <div className="col-box-83">
              <ItemContent filters={filters} results={results} searchingPhrase={searchingPhrase} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default YourInventory;
