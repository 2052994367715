import React, { useState } from 'react';
import Header from '../components/header/HeaderStyle2';
import ItemContent from '../components/layouts/all_items/ItemContent';
import SideBar from '../components/layouts/all_items/SideBar';

const AllItems = () => {
  const [filters, setFilters] = useState({});
  const [searchingPhrase, setSearchingPhrase] = useState('');

  return (
    <div className="home-8">
      <Header searchingPhrase={searchingPhrase} setSearchingPhrase={setSearchingPhrase} />
      <section className="tf-item tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-box-17">
              <SideBar setFilters={setFilters} />
            </div>

            <div className="col-box-83">
              <ItemContent filters={filters} searchingPhrase={searchingPhrase} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AllItems;
