import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { TabPanel, Tabs } from 'react-tabs';
import CardModal from '../CardModal';

import { mockData } from './mockItems';
import { applySearching } from '../your_inventory/ItemContent';

const gatherFilterConditions = (filters) => {
  const filterConditions = {};
  for (const category in filters) {
    for (const categoryItem in filters[category]) {
      if (filters[category][categoryItem] === true) {
        filterConditions[category] = filterConditions[category] || [];
        filterConditions[category].push(categoryItem);
      }
    }
  }
  return filterConditions;
};

export const getUniqueItems = (array) => {
  const seenNames = new Set();
  return array.filter((item) => {
    const name = item.meta.name;
    if (!seenNames.has(name)) {
      seenNames.add(name);
      return true;
    }
    return false;
  });
};

export const applyFiltersFor = (items, filters) => {
  const filterConditions = gatherFilterConditions(filters);

  const filtersNotApplied = Object.keys(filterConditions).length === 0;
  if (filtersNotApplied) return items;

  const filteredItems = items.filter((item) => {
    for (const category in filterConditions) {
      // in home 08 we don't have meta key, so we have to do ||
      const itemDetails = item?.meta || item;
      if (!filterConditions[category].includes(itemDetails[category])) continue;
      return true;
    }
    return false;
  });

  return filteredItems;
};

const ItemContent = ({ filters, searchingPhrase }) => {
  const [visible, setVisible] = useState(15);
  const [modalShow, setModalShow] = useState(false);

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 5);
  };

  const uniqueItems = applyFiltersFor(getUniqueItems(mockData), filters);
  const searchedItems = applySearching(uniqueItems, searchingPhrase);

  return (
    <Fragment>
      <div className="tf-section sc-explore-2">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="flat-tabs explore-tab">
                <Tabs>
                  <TabPanel>
                    {searchedItems.slice(0, visible).map((item) => (
                      <div
                        key={item.id}
                        className={`sc-card-product explode style2 mg-bt ${item.feature ? 'comingsoon' : ''} `}
                      >
                        <div className="card-media" style={{ height: '360px' }}>
                          <Link to="/item-batched" state={item}>
                            <img
                              src={`https://darkcity.infura-ipfs.io/ipfs/${item.meta.image}`}
                              alt={item.meta.name}
                              style={{ height: '100%', objectFit: 'cover' }}
                            />
                          </Link>
                        </div>
                        <div className="card-title">
                          <h5>
                            <Link to="/item-batched" state={item}>
                              {item.meta.name}
                            </Link>
                          </h5>
                        </div>
                        <div className="meta-info">
                          <div className="author">
                            <div className="info">
                              <span>{item.meta.type}</span>
                            </div>
                          </div>
                          <div
                            className="tags"
                            style={{
                              width: 'auto',
                              height: '30px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '0px 12px',
                            }}
                          >
                            {item.meta.rarity}
                          </div>
                        </div>
                      </div>
                    ))}
                    {visible < searchedItems.length && (
                      <div className="col-md-12 wrap-inner load-more text-center">
                        <Link
                          to="#"
                          id="load-more"
                          className="sc-button loadmore fl-button pri-3"
                          onClick={showMoreItems}
                        >
                          <span>Load More</span>
                        </Link>
                      </div>
                    )}
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </Fragment>
  );
};

export default ItemContent;
