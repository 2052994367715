const menus = [
  {
    id: 1,
    name: 'All items',
    links: '/all-items',
  },
  {
    id: 2,
    name: 'Inventory',
    links: '/your-inventory',
  },
];

export default menus;
