import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logodark from '../../assets/images/logo/logo_dark.png';
import logofooter from '../../assets/images/logo/logo2.png';
const Footer = () => {
  const socialList = [
    {
      icon: 'fab fa-twitter',
      link: 'https://x.com/darkcity_x',
    },
    {
      icon: 'icon-fl-vt',
      link: 'https://discord.gg/darkcity',
    },
    {
      icon: 'fas fa-info-circle',
      link: 'https://info.dark.city',
    },
  ];

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <div>
      <footer id="footer" className="footer-light-style clearfix bg-style" style={{ borderTop: '1px solid white' }}>
        <div
          className="themesflat-container"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <div>
            <h2>✹</h2>
            <p style={{ fontSize: '14px', display: 'block' }}>Dark Market v.0.0.1</p>
          </div>

          <div className="widget-social style-1 mg-t32" style={{ marginTop: 0 }}>
            <ul>
              {socialList.map((item, index) => (
                <li key={index}>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <i className={item.icon}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </footer>
      {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}
    </div>
  );
};

export default Footer;
