import React from 'react';
import { Modal } from 'react-bootstrap';

const CardModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton style={{ padding: 0 }}></Modal.Header>

      <div
        className="modal-body space-y-20 pd-40"
        style={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
          border: '1px solid white',
        }}
      >
        {props.render}
      </div>
    </Modal>
  );
};

export default CardModal;
