import { produce } from 'immer';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Accordion } from 'react-bootstrap-accordion';

const dataCategories = [
  {
    id: 'type',
    title: 'Type',
    content: [],
  },
  {
    id: 'rarity',
    title: 'Rarity',
    content: [],
  },
];

const SideBar = ({ setFilters, results }) => {
  const data = results.map((result) => result.data);

  const resultsNotReady = results.some((result) => result.data === undefined);

  //initialize filters
  useEffect(() => {
    setFilters(initialFilters);
  }, []);

  if (resultsNotReady) return;

  const uniqueRarities = [...new Set(data.map((item) => item.meta.rarity))];
  const uniqueTypes = [...new Set(data.map((item) => item.meta.type))];

  for (const type of uniqueTypes) {
    if (!dataCategories[0].content.some((category) => category.field === type)) {
      dataCategories[0].content.push({
        field: type,
        checked: false,
      });
    }
  }
  for (const rarity of uniqueRarities) {
    if (!dataCategories[1].content.some((category) => category.field === rarity)) {
      dataCategories[1].content.push({
        field: rarity,
        checked: false,
      });
    }
  }

  const initialFilters = {};
  dataCategories.forEach((category) => {
    initialFilters[category.id] = {};
    category.content.forEach((contentItem) => {
      initialFilters[category.id][contentItem.field] = contentItem.checked;
    });
  });

  const handleChecked = (category, field) => {
    setFilters(
      produce((draft) => {
        draft[category][field] = !draft[category][field];
      })
    );
  };

  return (
    <div id="side-bar" className="side-bar style-3 item">
      <div className="widget widget-filter style-1 mgbt-0">
        <div className="header-widget-filter">
          <h4 className="title-widget">Filter</h4>
        </div>
      </div>
      <div className="divider"></div>
      <div className="wrap-category">
        {dataCategories.map((category, index) => (
          <div key={index} className="widget widget-category boder-bt">
            <Accordion title={category.title} show={true}>
              <form action="#">
                {category.content.map((item, index) => (
                  <div key={index}>
                    <label>
                      <span>{item.field}</span>
                      <span className="pst-re">
                        <input
                          type="checkbox"
                          defaultChecked={item.checked}
                          onChange={() => handleChecked(category.id, item.field)}
                        />
                        <span className="btn-checkbox"></span>
                      </span>
                    </label>
                    <br />
                  </div>
                ))}
              </form>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideBar;
