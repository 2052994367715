import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import CardModal from '../../components/layouts/CardModal';

import unisat_logo from '../../assets/images/icon/unisat_logo.png';
import styles from './styles.module.css';
import { useUnisat } from './hooks/useUnisat';

const WalletConnect = () => {
  const [modalShow, setModalShow] = useState(false);
  const { handleUnisatConnect, connected, unisatInstalled } = useUnisat(setModalShow);

  const data = [
    {
      img: unisat_logo,
      title: 'Unisat',
      onClick: handleUnisatConnect,
    },
  ];

  useEffect(() => {
    if (connected && !unisatInstalled) setModalShow(true);
  }, [connected, unisatInstalled]);

  //unisatInstalled && connected - wallet connectedModal

  const getCorrectModalContent = () => {
    //refactor - boolean obsession
    if (unisatInstalled && connected) return walletConnectedModal;
    if (unisatInstalled && !connected) return notLoggedInModal;
    if (!unisatInstalled && !connected) return walletNotInstalledModal;
  };

  const walletConnectedModal = (
    <>
      <h3>Wallet connected!</h3>
      <button
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#popup_bid_success"
        data-dismiss="modal"
        style={{ width: 'auto', padding: '10px 26px' }}
        aria-label="Continue"
        onClick={() => setModalShow(false)}
      >
        Continue
      </button>
    </>
  );

  const notLoggedInModal = (
    <>
      <h3>Please log into your Unisat account</h3>
      <button
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#popup_bid_success"
        data-dismiss="modal"
        style={{ width: 'auto', padding: '10px 26px' }}
        aria-label="Continue"
        onClick={() => setModalShow(false)}
      >
        Continue
      </button>
    </>
  );

  const walletNotInstalledModal = (
    <>
      <h3>Please install Unisat wallet</h3>
      <a
        href="https://chromewebstore.google.com/detail/unisat-wallet/ppbibelpcjmhbdihakflkdcoccbgbkpo"
        target="_blank"
        rel="noreferrer"
        className="btn btn-primary"
        style={{ width: 'auto', padding: '10px 26px' }}
      >
        Install Unisat
      </a>
    </>
  );

  return (
    <div>
      <Header />

      <div className="tf-connect-wallet tf-section" style={{ paddingTop: '180px' }}>
        <div className="themesflat-container">
          <div className="row" style={{ gap: '100px' }}>
            <div className="col-12">
              <h2 className="tf-title-heading ct style-2 mg-bt-12">Connect Your Wallet</h2>
            </div>
            <div className="col-md-12">
              <div className="sc-box-icon-inner style-2">
                {data.map((item, index) => (
                  <div key={index} className="sc-box-icon" style={{ borderRadius: 0 }}>
                    <div className="img">
                      <img src={item.img} alt="Axies" />
                    </div>
                    <h4 className="heading">
                      {/* Jak się zrobi connected, to chcemy przenieść */}

                      {/* for now, we just move to unisat, later there will be logging i guess */}
                      {/* <Link to="/login">{item.title}</Link> */}
                      <button className={styles.unstyledButton} onClick={item.onClick}>
                        {item.title}
                      </button>
                    </h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <CardModal show={modalShow} onHide={() => setModalShow(false)} render={getCorrectModalContent()} />
    </div>
  );
};

export default WalletConnect;
