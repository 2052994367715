import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TabPanel, Tabs } from 'react-tabs';
import CardModal from '../CardModal';
import { mockData } from './mockItems';
import HeaderStyle2 from '../../header/HeaderStyle2';
import { applySearching } from '../your_inventory/ItemContent';

const ClusterResolvedItems = () => {
  const [visible, setVisible] = useState(15);
  const [modalShow, setModalShow] = useState(false);
  const location = useLocation();
  const [searchingPhrase, setSearchingPhrase] = useState('');

  const item = location.state;
  const itemName = item.meta.name;

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 5);
  };

  const allItemsWithGivenName = mockData.filter((item) => item.meta.name === itemName);
  const searchedItems = applySearching(allItemsWithGivenName, searchingPhrase);

  return (
    <div className="home-8">
      <HeaderStyle2 searchingPhrase={searchingPhrase} setSearchingPhrase={setSearchingPhrase} />
      <div className="tf-section sc-explore-2" style={{ marginTop: '50px' }}>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="flat-tabs explore-tab">
                <Tabs>
                  <TabPanel>
                    {searchedItems.slice(0, visible).map((item) => (
                      <div
                        key={item.id}
                        className={`sc-card-product explode style2 mg-bt ${item.feature ? 'comingsoon' : ''} `}
                      >
                        <div className="card-media" style={{ height: '360px' }}>
                          <Link to="/item-details-01" state={item}>
                            <img
                              src={`https://darkcity.infura-ipfs.io/ipfs/${item.meta.image}`}
                              alt={item.meta.name}
                              style={{ height: '100%', objectFit: 'cover' }}
                            />
                          </Link>
                        </div>
                        <div className="card-title">
                          <h5>
                            <Link to="/item-details-01" state={item}>
                              {item.meta.name}
                            </Link>
                          </h5>
                        </div>
                        <div className="meta-info">
                          <div className="author">
                            <div className="info">
                              <span>{item.meta.type}</span>
                            </div>
                          </div>
                          <div
                            className="tags"
                            style={{
                              width: 'auto',
                              height: '30px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '0px 12px',
                            }}
                          >
                            {item.meta.rarity}
                          </div>
                        </div>
                      </div>
                    ))}
                    {visible < searchedItems.length && (
                      <div className="col-md-12 wrap-inner load-more text-center">
                        <button id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}>
                          <span>Load More</span>
                        </button>
                      </div>
                    )}
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default ClusterResolvedItems;
